html,
body {
  margin: 0;
  height: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 12pt;
  background-color: #f7f7f7;
}

button {
  font-family: "Roboto", sans-serif !important;
}

h1,
h2 {
  margin: 15px 0;
  padding: 15px 0;
}

table {
  /* border: 3px solid #00205B; */
  border-collapse: collapse;
}

thead {
  background-color: #00205b;
  color: #ffffff;
}

.standard-table tr {
  border: 3px solid #00205b;
}

.selectable-row:hover,
.selectable-row:focus {
  background-color: #fae100;
  color: #00205b;
}

th,
td {
  /* min-width: 140px; */
  padding: 5px 15px;
  text-align: left;
}

td a button {
  cursor: pointer;
  word-break: break-all;
}

.b-yellow-f-blue {
  padding: 15px;
  background-color: #fae100;
  color: #00205b;
}

.b-blue-f-white {
  padding: 15px;
  background-color: #00205b;
  color: #ffffff;
}

.b-blue-f-white-button {
  background-color: #00205b;
  color: #ffffff;
  border: 2px solid #ffffff;
  padding: 15px;
}

.b-blue-f-white-button:hover,
.b-blue-f-white-button:focus {
  background-color: #fae100;
  border: 2px solid #fae100;
  color: #00205b;
  padding: 15px;
}

.b-yellow-f-blue-button {
  padding: 15px;
  background-color: #fae100;
  border: 2px solid #fae100;
  color: #00205b;
}

.b-yellow-f-blue-button:hover,
.b-yellow-f-blue-button:focus {
  background-color: #00205b;
  border: 2px solid #00205b;
  color: #ffffff;
}

#loginButton {
  padding: 15px;
  border: 2px solid #fae100;
  background-color: #fae100;
  color: #00205b;
}

#loginButton:hover,
#loginButton:focus {
  border: 2px solid #fae100;
  background-color: #ffffff;
  color: #00205b;
}

input {
  font-family: "Roboto", sans-serif;
  font-size: 12pt;
  padding: 5px;
}

::placeholder {
  color: #00205b;
  font-weight: bold;
}

.bar-button {
  padding: 10px 15px;
  margin: 0 5px;
  border: 2px solid #ffffff;
  background-color: #00205b;
  color: #ffffff;
  cursor: pointer;
  border-radius: 20px;
}

.bar-button:hover,
.bar-button:focus {
  color: #00205b;
  background-color: #ffffff;
}

.b-white-f-blue-button {
  font-weight: bold;
  font-size: 14pt;
  padding: 10px 15px;
  background-color: #ffffff;
  border: 2px solid #00205b;
  color: #00205b;
  cursor: pointer;
  border-radius: 20px;
}

.b-white-f-blue-button:hover,
.b-white-f-blue-button:focus {
  color: #ffffff;
  background-color: #00205b;
}

.bar-button.active {
  background-color: #ffffff;
  color: #00205b;
}

select:focus {
  outline: none !important;
}

.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 20px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content */

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 40%;
}

/* The Close Button */

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-body {
  height: 250px;
  overflow-y: auto;
}

.display-box {
  margin-right: 10px;
}

.display-box label {
  display: block;
}

.display-box input {
  height: 33px;
  width: 290px;
  border: 2px solid #00205b;
}

.flat-button {
  border: none;
  font-size: 14px;
  height: 30px;
  color: white;
  cursor: pointer;
}

.button-cancel {
  background-color: #fe4e03;
}
.button-accept {
  background-color: #1cbad4;
}

.button-hold,
.button-leave {
  background-color: #3cb371;
}

.button-drop,
.button-end {
  background-color: #ea7070;
}
.button-trans {
  background-color: #00205b;
}

#feedbackBtn {
  color: #00205b;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  margin-left: 49px;
}

#feedbackMsgContainer {
  margin-left: 4px;
}

#feedbackBtn:hover,
#feedbackBtn:focus {
  background-color: #fae100;
  cursor: context-menu;
  outline: none !important;
}

.feedbackBtnClicked {
  background-color: #00205b;
  color: #ffffff !important;
}

.feedbackBtnClicked:hover {
  color: #00205b !important;
}

#feedbackOption,
#feedbackExperience,
#successfulMsg {
  height: 584px;
  width: 346px;
  background-color: #ffffff;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}

.popover-body {
  padding: 0;
}

#optionHeaderWrapper,
#experienceHeaderWrapper,
#successfulHeaderWrapper {
  display: flex;
}

#optionHeader {
  height: 70px;
  width: 326px;
  padding-right: 89px;
  color: #00205b;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  padding-top: 27px;
  padding-left: 24px;
}

#closeIcon {
  font-size: 16px;
  font-weight: bold;
  color: #00205b;
  margin-top: 23px;
  height: 23px;
  text-align: right;
  margin-right: 25px;
  width: 20px;
}

#optionSubHeader {
  height: 75px;
  width: 297px;
  color: #00205b;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 19px;
  padding-top: 16px;
  padding-left: 24px;
}

#optionSubHeader:hover,
#optionHeader:hover,
#closeIcon:hover,
#experienceHeader:hover {
  cursor: context-menu;
}

.option.firstOption {
  margin-top: 16px;
}

.option:focus,
.option:hover,
.expOption:focus,
.expOption:hover {
  color: #ffffff !important;
  background-color: #00205b !important;
  cursor: context-menu;
}

.option {
  margin-top: 8px;
  color: #00205b;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  margin-left: 24px;
  height: 36px;
  width: 297px;
  border-radius: 5px;
  background-color: #fae100;
  padding-left: 12px;
  padding-top: 8px;
}

#experienceHeader {
  height: 83px;
  width: 327px;
  color: #00205b;
  font-family: "Roboto" sans-serif;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 28px;
  padding-top: 27px;
  padding-left: 24px;
}

.expOption.expFirstOption {
  margin-top: 76px;
}

.expOption {
  margin-top: 16px;
  color: #00205b;
  font-family: "Roboto Black";
  font-size: 26px;
  letter-spacing: 0;
  line-height: 20px;
  height: 80px;
  width: 297px;
  border-radius: 5px;
  background-color: #fae100;
  margin-left: 24px;
  padding-top: 28px;
  text-align: center;
  font-weight: bold;
}

.activeDisplayIcon {
  display: none;
}

#impossible:hover #impossibleIcon {
  display: none;
}

#impossible:hover #impossibleWhiteIcon {
  display: inline;
}

#managable:hover #managableIcon {
  display: none;
}

#managable:hover #managableWhiteIcon {
  display: inline;
}

#difficult:hover #difficultIcon {
  display: none;
}

#difficult:hover #difficultWhiteIcon {
  display: inline;
}

#managable:focus #managableIcon {
  display: none !important;
}
#managable:focus #managableWhiteIcon {
  display: inline !important;
}
#impossible:focus #impossibleIcon {
  display: none !important;
}
#impossible:focus #impossibleWhiteIcon {
  display: inline !important;
}
#difficult:focus #difficultIcon {
  display: none !important;
}
#difficult:focus #difficultWhiteIcon {
  display: inline !important;
}

#successfulHeaderWrapper {
  justify-content: flex-end;
}

#successfulMark {
  margin-top: 79px;
  text-align: center;
}

#successfulMessage {
  height: 84px;
  color: #00205b;
  font-family: "Roboto" sans-serif;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  margin-top: 40px;
  text-align: center;
  padding-left: 54px;
  padding-right: 54px;
}

.popover-body *:focus {
  outline: none;
}

#optionHeader:focus,
#optionSubHeader:focus,
#experienceHeader:focus,
#successfulMessage:focus {
  background-color: #ffffff !important;
}

.successfulSection:focus {
  background-color: #ffffff !important;
}
